import React, { forwardRef, useRef } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../../Icon';
import DeleteIcon from '../../../../public/static/images/new_search/icons/icon-delete.svg';
import { useCombinedRefs } from '../../../../hooks/useCombinedRefs';

const NumberField = forwardRef(
  (
    {
      name,
      placeholder,
      id,
      stat,
      fieldValue = '',
      onChange,
      onReset,
      wrapperClassName,
      floatingPlaceholder,
      floatingPlaceholderClassName,
      onFocus,
      onBlur
    },
    ref
  ) => {
    const { change } = useForm();
    const { values } = useFormState();
    const inputRef = useRef();
    const combinedRef = useCombinedRefs(ref, inputRef);

    const resetValue = () => {
      change(name);
      if (onReset) {
        onReset();
      }
    };

    const noLeadingZeroNumber = (value = '') => {
      if (
        typeof value === 'string' &&
        value.startsWith('0') &&
        value.length > 1
      ) {
        return value.replace(/^0/, '').replace(/\D/g, '');
      }

      return value;
    };
    const onlyNums = (value = '') => {
      return typeof value === 'string' ? value.replace(/\D/g, '') : value;
    };

    const focusInput = () => {
      combinedRef.current?.focus();
    };

    const isFocused = document.activeElement === combinedRef.current;

    return (
      <div className={cn('filters__field-wrapper', wrapperClassName)}>
        {floatingPlaceholder && (
          <div
            role="presentation"
            onClick={focusInput}
            className={cn(
              'filters__floating-placeholder',
              floatingPlaceholderClassName,
              {
                'filters__floating-placeholder_active': isFocused || fieldValue
              }
            )}
          >
            {floatingPlaceholder}
          </div>
        )}

        <Field
          ref={combinedRef}
          id={id}
          name={name}
          data-stat={stat}
          pattern="[0-9]*"
          component="input"
          autoComplete="off"
          inputMode="numeric"
          maxLength="9"
          parse={onlyNums}
          format={noLeadingZeroNumber}
          placeholder={placeholder}
          className="filters__text-field"
          onFocus={onFocus}
          onBlur={onBlur}
          {...(onChange && {
            onChange: e => {
              onChange(noLeadingZeroNumber(onlyNums(e.target.value)));
            },
            initialValue: fieldValue
          })}
        />
        <div
          tabIndex={0}
          role="button"
          onClick={resetValue}
          onKeyPress={resetValue}
          className="filters__delete-icon"
          style={{ visibility: values[name] ? 'visible' : 'hidden' }}
        >
          <Icon width={15} height={15} IconComponent={DeleteIcon} />
        </div>
      </div>
    );
  }
);

NumberField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  stat: PropTypes.string,
  placeholder: PropTypes.string,
  fieldValue: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  wrapperClassName: PropTypes.string,
  floatingPlaceholder: PropTypes.string,
  floatingPlaceholderClassName: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

NumberField.displayName = 'NumberField';

export default NumberField;
